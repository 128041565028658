define("discourse/plugins/chat/discourse/components/chat/navbar/thread-tracking-dropdown", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/helpers/concat-class", "discourse/lib/ajax-error", "discourse/lib/notification-levels", "discourse/plugins/chat/discourse/components/chat-thread-tracking-dropdown", "discourse/plugins/chat/discourse/models/user-chat-thread-membership", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _concatClass, _ajaxError, _notificationLevels, _chatThreadTrackingDropdown, _userChatThreadMembership, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatNavbarThreadTrackingDropdown extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chatApi", [_service.service]))();
    #chatApi = (() => (dt7948.i(this, "chatApi"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "persistedNotificationLevel", [_tracking.tracked], function () {
      return true;
    }))();
    #persistedNotificationLevel = (() => (dt7948.i(this, "persistedNotificationLevel"), void 0))();
    get threadNotificationLevel() {
      return this.membership?.notificationLevel || _notificationLevels.NotificationLevels.REGULAR;
    }
    get membership() {
      return this.args.thread.currentUserMembership;
    }
    async updateThreadNotificationLevel(newNotificationLevel1) {
      this.persistedNotificationLevel = false;
      let currentNotificationLevel1;
      if (this.membership) {
        currentNotificationLevel1 = this.membership.notificationLevel;
        this.membership.notificationLevel = newNotificationLevel1;
      } else {
        this.args.thread.currentUserMembership = _userChatThreadMembership.default.create({
          notification_level: newNotificationLevel1,
          last_read_message_id: null
        });
      }
      try {
        const response1 = await this.chatApi.updateCurrentUserThreadNotificationsSettings(this.args.thread.channel.id, this.args.thread.id, {
          notificationLevel: newNotificationLevel1
        });
        this.membership.last_read_message_id = response1.membership.last_read_message_id;
        this.persistedNotificationLevel = true;
      } catch (error1) {
        this.membership.notificationLevel = currentNotificationLevel1;
        (0, _ajaxError.popupAjaxError)(error1);
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "updateThreadNotificationLevel", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <ThreadTrackingDropdown
          @value={{this.threadNotificationLevel}}
          @onChange={{this.updateThreadNotificationLevel}}
          class={{concatClass
            "c-navbar__thread-tracking-dropdown"
            (if this.persistedNotificationLevel "-persisted")
          }}
        />
      
    */
    {
      "id": "hqoyZVth",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[16,0,[28,[32,1],[\"c-navbar__thread-tracking-dropdown\",[52,[30,0,[\"persistedNotificationLevel\"]],\"-persisted\"]],null]]],[[\"@value\",\"@onChange\"],[[30,0,[\"threadNotificationLevel\"]],[30,0,[\"updateThreadNotificationLevel\"]]]],null],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/forums/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/navbar/thread-tracking-dropdown.js",
      "scope": () => [_chatThreadTrackingDropdown.default, _concatClass.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatNavbarThreadTrackingDropdown;
});