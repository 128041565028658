define("discourse/plugins/chat/discourse/connectors/above-site-header/chat-body-class", ["exports", "@glimmer/component", "@ember/service", "discourse/helpers/body-class", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _bodyClass, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatBodyClass extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chatStateManager", [_service.service]))();
    #chatStateManager = (() => (dt7948.i(this, "chatStateManager"), void 0))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.chatStateManager.hasPreloadedChannels}}
          {{bodyClass "has-preloaded-chat-channels"}}
        {{/if}}
      
    */
    {
      "id": "1y64KgCo",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"chatStateManager\",\"hasPreloadedChannels\"]],[[[1,\"      \"],[1,[28,[32,0],[\"has-preloaded-chat-channels\"],null]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/forums/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/connectors/above-site-header/chat-body-class.js",
      "scope": () => [_bodyClass.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatBodyClass;
});