define("discourse/plugins/chat/discourse/components/chat/navbar/new-channel-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/plugins/chat/discourse/components/chat/modal/create-channel", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _createChannel2, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatNavbarNewChannelButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chatStateManager", [_service.service]))();
    #chatStateManager = (() => (dt7948.i(this, "chatStateManager"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    createChannel() {
      this.modal.show(_createChannel2.default);
    }
    static #_5 = (() => dt7948.n(this.prototype, "createChannel", [_object.action]))();
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.currentUser.staff}}
          <DButton
            @action={{this.createChannel}}
            @icon="plus"
            @label={{if this.site.desktopView "chat.create_channel.title"}}
            class="c-navbar__new-channel-button btn-transparent"
          />
        {{/if}}
      
    */
    {
      "id": "zR3/uddg",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"currentUser\",\"staff\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"c-navbar__new-channel-button btn-transparent\"]],[[\"@action\",\"@icon\",\"@label\"],[[30,0,[\"createChannel\"]],\"plus\",[52,[30,0,[\"site\",\"desktopView\"]],\"chat.create_channel.title\"]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/forums/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/navbar/new-channel-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatNavbarNewChannelButton;
});