define("discourse/plugins/chat/discourse/components/chat/message/left-gutter", ["exports", "@glimmer/component", "@ember/helper", "@ember/routing", "@ember/service", "truth-helpers", "discourse/components/bookmark-icon", "discourse-common/helpers/d-icon", "discourse/plugins/chat/discourse/helpers/format-chat-date", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _routing, _service, _truthHelpers, _bookmarkIcon, _dIcon, _formatChatDate, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatMessageLeftGutter extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="chat-message-left-gutter">
          {{#if @message.reviewableId}}
            <LinkTo
              @route="review.show"
              @model={{@message.reviewableId}}
              class="chat-message-left-gutter__flag"
            >
              {{dIcon "flag" title="chat.flagged"}}
            </LinkTo>
          {{else if (eq @message.userFlagStatus 0)}}
            <div class="chat-message-left-gutter__flag">
              {{dIcon "flag" title="chat.you_flagged"}}
            </div>
          {{else if this.site.desktopView}}
            <span class="chat-message-left-gutter__date">
              {{formatChatDate
                @message
                (hash mode="tiny" threadContext=@threadContext)
              }}
            </span>
          {{/if}}
          {{#if @message.bookmark}}
            <span class="chat-message-left-gutter__bookmark">
              <BookmarkIcon @bookmark={{@message.bookmark}} />
            </span>
          {{/if}}
        </div>
      
    */
    {
      "id": "Labpfuov",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-message-left-gutter\"],[12],[1,\"\\n\"],[41,[30,1,[\"reviewableId\"]],[[[1,\"        \"],[8,[32,0],[[24,0,\"chat-message-left-gutter__flag\"]],[[\"@route\",\"@model\"],[\"review.show\",[30,1,[\"reviewableId\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[32,1],[\"flag\"],[[\"title\"],[\"chat.flagged\"]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],[[[41,[28,[32,2],[[30,1,[\"userFlagStatus\"]],0],null],[[[1,\"        \"],[10,0],[14,0,\"chat-message-left-gutter__flag\"],[12],[1,\"\\n          \"],[1,[28,[32,1],[\"flag\"],[[\"title\"],[\"chat.you_flagged\"]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"site\",\"desktopView\"]],[[[1,\"        \"],[10,1],[14,0,\"chat-message-left-gutter__date\"],[12],[1,\"\\n          \"],[1,[28,[32,3],[[30,1],[28,[32,4],null,[[\"mode\",\"threadContext\"],[\"tiny\",[30,2]]]]],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]],null]],[]]]],[]]],[41,[30,1,[\"bookmark\"]],[[[1,\"        \"],[10,1],[14,0,\"chat-message-left-gutter__bookmark\"],[12],[1,\"\\n          \"],[8,[32,5],null,[[\"@bookmark\"],[[30,1,[\"bookmark\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@message\",\"@threadContext\"],false,[\"if\"]]",
      "moduleName": "/var/www/forums/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message/left-gutter.js",
      "scope": () => [_routing.LinkTo, _dIcon.default, _truthHelpers.eq, _formatChatDate.default, _helper.hash, _bookmarkIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatMessageLeftGutter;
});