define("discourse/plugins/chat/discourse/components/chat/navbar/toggle-drawer-button", ["exports", "@glimmer/component", "@ember/service", "discourse/components/d-button", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _dButton, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatNavbarToggleDrawerButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatStateManager", [_service.service]))();
    #chatStateManager = (() => (dt7948.i(this, "chatStateManager"), void 0))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          @icon={{if
            this.chatStateManager.isDrawerExpanded
            "angle-double-down"
            "angle-double-up"
          }}
          @action={{this.chat.toggleDrawer}}
          @title={{if
            this.chatStateManager.isDrawerExpanded
            "chat.collapse"
            "chat.expand"
          }}
          class="btn-transparent no-text c-navbar__toggle-drawer-button"
        />
      
    */
    {
      "id": "G+B73Cw9",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"btn-transparent no-text c-navbar__toggle-drawer-button\"]],[[\"@icon\",\"@action\",\"@title\"],[[52,[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],\"angle-double-down\",\"angle-double-up\"],[30,0,[\"chat\",\"toggleDrawer\"]],[52,[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],\"chat.collapse\",\"chat.expand\"]]],null],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/forums/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/navbar/toggle-drawer-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatNavbarToggleDrawerButton;
});