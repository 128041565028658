define("discourse/plugins/chat/discourse/components/chat/drawer-routes/channel-threads", ["exports", "@glimmer/component", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "@ember/template", "discourse/helpers/replace-emoji", "discourse/lib/ajax-error", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/navbar", "discourse/plugins/chat/discourse/components/chat-thread-list", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _didInsert, _service, _template2, _replaceEmoji, _ajaxError, _discourseI18n, _navbar, _chatThreadList, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatDrawerRoutesChannelThreads extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatChannelsManager", [_service.service]))();
    #chatChannelsManager = (() => (dt7948.i(this, "chatChannelsManager"), void 0))();
    backLinkTitle = (() => _discourseI18n.default.t("chat.return_to_list"))();
    get title() {
      return (0, _template2.htmlSafe)(_discourseI18n.default.t("chat.threads.list") + " - " + (0, _replaceEmoji.default)(this.chat.activeChannel.title));
    }
    async fetchChannel() {
      if (!this.args.params?.channelId) {
        return;
      }
      try {
        const channel1 = await this.chatChannelsManager.find(this.args.params.channelId);
        this.chat.activeChannel = channel1;
      } catch (error1) {
        (0, _ajaxError.popupAjaxError)(error1);
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "fetchChannel", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.chat.activeChannel}}
          <Navbar @onClick={{this.chat.toggleDrawer}} as |navbar|>
            <navbar.BackButton
              @title={{this.backLinkTitle}}
              @route="chat.channel"
              @routeModels={{this.chat.activeChannel.routeModels}}
            />
            <navbar.Title @title={{this.title}} @icon="discourse-threads" />
            <navbar.Actions as |a|>
              <a.ToggleDrawerButton />
              <a.FullPageButton />
              <a.CloseDrawerButton />
            </navbar.Actions>
          </Navbar>
        {{/if}}
    
        <div class="chat-drawer-content" {{didInsert this.fetchChannel}}>
          {{#if this.chat.activeChannel}}
            <ChatThreadList
              @channel={{this.chat.activeChannel}}
              @includeHeader={{false}}
            />
          {{/if}}
        </div>
      
    */
    {
      "id": "awL6QXPi",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"chat\",\"activeChannel\"]],[[[1,\"      \"],[8,[32,0],null,[[\"@onClick\"],[[30,0,[\"chat\",\"toggleDrawer\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,1,[\"BackButton\"]],null,[[\"@title\",\"@route\",\"@routeModels\"],[[30,0,[\"backLinkTitle\"]],\"chat.channel\",[30,0,[\"chat\",\"activeChannel\",\"routeModels\"]]]],null],[1,\"\\n        \"],[8,[30,1,[\"Title\"]],null,[[\"@title\",\"@icon\"],[[30,0,[\"title\"]],\"discourse-threads\"]],null],[1,\"\\n        \"],[8,[30,1,[\"Actions\"]],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[30,2,[\"ToggleDrawerButton\"]],null,null,null],[1,\"\\n          \"],[8,[30,2,[\"FullPageButton\"]],null,null,null],[1,\"\\n          \"],[8,[30,2,[\"CloseDrawerButton\"]],null,null,null],[1,\"\\n        \"]],[2]]]]],[1,\"\\n      \"]],[1]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[11,0],[24,0,\"chat-drawer-content\"],[4,[32,1],[[30,0,[\"fetchChannel\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"chat\",\"activeChannel\"]],[[[1,\"        \"],[8,[32,2],null,[[\"@channel\",\"@includeHeader\"],[[30,0,[\"chat\",\"activeChannel\"]],false]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"navbar\",\"a\"],false,[\"if\"]]",
      "moduleName": "/var/www/forums/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/drawer-routes/channel-threads.js",
      "scope": () => [_navbar.default, _didInsert.default, _chatThreadList.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatDrawerRoutesChannelThreads;
});