define("discourse/plugins/chat/discourse/components/chat/message-creator/add-members", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "truth-helpers", "discourse/components/d-button", "discourse/lib/ajax-error", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/message-creator/members-count", "discourse/plugins/chat/discourse/components/chat/message-creator/members-selector", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _truthHelpers, _dButton, _ajaxError, _discourseI18n, _membersCount, _membersSelector, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AddMembers extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatApi", [_service.service]))();
    #chatApi = (() => (dt7948.i(this, "chatApi"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "toasts", [_service.service]))();
    #toasts = (() => (dt7948.i(this, "toasts"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "loadingSlider", [_service.service]))();
    #loadingSlider = (() => (dt7948.i(this, "loadingSlider"), void 0))();
    get membersCount() {
      const userCount1 = this.args.members?.reduce((acc1, member1) => {
        if (member1.type === "group") {
          return acc1 + member1.model.chat_enabled_user_count;
        } else {
          return acc1 + 1;
        }
      }, 0);
      return userCount1 + (this.args.channel?.membershipsCount ?? 0);
    }
    get maxMembers() {
      return this.siteSettings.chat_max_direct_message_users;
    }
    async saveGroupMembers() {
      try {
        this.loadingSlider.transitionStarted();
        const usernames1 = this.args.members.filter(member1 => member1.type === "user").mapBy("model.username");
        const groups1 = this.args.members.filter(member1 => member1.type === "group").mapBy("model.name");
        await this.chatApi.addMembersToChannel(this.args.channel.id, {
          usernames: usernames1,
          groups: groups1
        });
        this.toasts.success({
          data: {
            message: _discourseI18n.default.t("saved")
          }
        });
        this.router.transitionTo("chat.channel", ...this.args.channel.routeModels);
      } catch (error1) {
        (0, _ajaxError.popupAjaxError)(error1);
      } finally {
        this.loadingSlider.transitionEnded();
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "saveGroupMembers", [_object.action]))();
    static #_8 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="chat-message-creator__add-members-container">
          <div class="chat-message-creator__add-members">
            <MembersCount @count={{this.membersCount}} @max={{this.maxMembers}} />
    
            <MembersSelector
              @channel={{@channel}}
              @members={{@members}}
              @onChange={{@onChangeMembers}}
              @close={{@close}}
              @cancel={{@cancel}}
              @membersCount={{this.membersCount}}
              @maxReached={{gte this.membersCount this.maxMembers}}
            />
    
            {{#if @members.length}}
              <div class="chat-message-creator__add-members-footer-container">
                <div class="chat-message-creator__add-members-footer">
                  <DButton class="btn-flat" @label="cancel" @action={{@cancel}} />
    
                  <DButton
                    class="btn-primary add-to-channel"
                    @label="chat.direct_message_creator.add_to_channel"
                    @action={{this.saveGroupMembers}}
                  />
                </div>
              </div>
            {{/if}}
          </div>
        </div>
      
    */
    {
      "id": "SlgUiaoR",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-message-creator__add-members-container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat-message-creator__add-members\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@count\",\"@max\"],[[30,0,[\"membersCount\"]],[30,0,[\"maxMembers\"]]]],null],[1,\"\\n\\n        \"],[8,[32,1],null,[[\"@channel\",\"@members\",\"@onChange\",\"@close\",\"@cancel\",\"@membersCount\",\"@maxReached\"],[[30,1],[30,2],[30,3],[30,4],[30,5],[30,0,[\"membersCount\"]],[28,[32,2],[[30,0,[\"membersCount\"]],[30,0,[\"maxMembers\"]]],null]]],null],[1,\"\\n\\n\"],[41,[30,2,[\"length\"]],[[[1,\"          \"],[10,0],[14,0,\"chat-message-creator__add-members-footer-container\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"chat-message-creator__add-members-footer\"],[12],[1,\"\\n              \"],[8,[32,3],[[24,0,\"btn-flat\"]],[[\"@label\",\"@action\"],[\"cancel\",[30,5]]],null],[1,\"\\n\\n              \"],[8,[32,3],[[24,0,\"btn-primary add-to-channel\"]],[[\"@label\",\"@action\"],[\"chat.direct_message_creator.add_to_channel\",[30,0,[\"saveGroupMembers\"]]]],null],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@channel\",\"@members\",\"@onChangeMembers\",\"@close\",\"@cancel\"],false,[\"if\"]]",
      "moduleName": "/var/www/forums/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message-creator/add-members.js",
      "scope": () => [_membersCount.default, _membersSelector.default, _truthHelpers.gte, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AddMembers;
});