define("discourse/plugins/chat/discourse/components/chat/navbar/index", ["exports", "@glimmer/component", "@ember/helper", "@ember/modifier", "@ember/object", "discourse/helpers/concat-class", "discourse/helpers/noop", "discourse/plugins/chat/discourse/modifiers/chat/on-resize", "discourse/plugins/chat/discourse/components/chat/navbar/actions", "discourse/plugins/chat/discourse/components/chat/navbar/back-button", "discourse/plugins/chat/discourse/components/chat/navbar/channel-title", "discourse/plugins/chat/discourse/components/chat/navbar/title", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _modifier, _object, _concatClass, _noop, _onResize, _actions, _backButton, _channelTitle, _title, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatNavbar extends _component.default {
    handleResize(entries1) {
      for (let entry1 of entries1) {
        const height1 = entry1.target.clientHeight;
        requestAnimationFrame(() => {
          document.documentElement.style.setProperty("--chat-header-expanded-offset", `${height1}px`);
        });
      }
    }
    static #_ = (() => dt7948.n(this.prototype, "handleResize", [_object.action]))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{! template-lint-disable no-invalid-interactive }}
        <div
          class={{concatClass
            "c-navbar-container"
            (if @onClick "-clickable")
            (if @showFullTitle "-full-title")
          }}
          {{on "click" (if @onClick @onClick (noop))}}
          {{ChatOnResize this.handleResize}}
        >
          <nav class="c-navbar">
            {{yield
              (hash
                BackButton=BackButton
                ChannelTitle=ChannelTitle
                Title=Title
                Actions=Actions
              )
            }}
          </nav>
        </div>
      
    */
    {
      "id": "SnhcrF61",
      "block": "[[[1,\"\\n\"],[1,\"    \"],[11,0],[16,0,[28,[32,0],[\"c-navbar-container\",[52,[30,1],\"-clickable\"],[52,[30,2],\"-full-title\"]],null]],[4,[32,1],[\"click\",[52,[30,1],[30,1],[28,[32,2],null,null]]],null],[4,[32,3],[[30,0,[\"handleResize\"]]],null],[12],[1,\"\\n      \"],[10,\"nav\"],[14,0,\"c-navbar\"],[12],[1,\"\\n        \"],[18,3,[[28,[32,4],null,[[\"BackButton\",\"ChannelTitle\",\"Title\",\"Actions\"],[[32,5],[32,6],[32,7],[32,8]]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@onClick\",\"@showFullTitle\",\"&default\"],false,[\"if\",\"yield\"]]",
      "moduleName": "/var/www/forums/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/navbar/index.js",
      "scope": () => [_concatClass.default, _modifier.on, _noop.default, _onResize.default, _helper.hash, _backButton.default, _channelTitle.default, _title.default, _actions.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatNavbar;
});